import React from "react";
import MultiplePizzas from "../assets/multiplePizzas.jpeg";
import "../styles/About.css";

const About = () => {
  return (
    <div className="about">
      <div
        className="aboutTop"
        style={{ backgroundImage: `url(${MultiplePizzas})` }}
      ></div>
      <div className="aboutBottom">
        <h1>ABOUT US</h1>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Porro
          suscipit assumenda vel minus debitis delectus labore odit similique ut
          placeat itaque ea dolores, ullam laboriosam fugiat reprehenderit
          consequatur facere accusantium. Lorem ipsum dolor, sit amet
          consectetur adipisicing elit. Vero distinctio iste eum ipsa minima
          quos? 
        </p>
      </div>
    </div>
  );
};

export default About;
